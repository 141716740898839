import { styled } from 'styled-components';


export default styled.div`

    footer {
        background-color: #222222;
        margin-top: auto;
        color: #fff;
        padding: 4rem 0 2rem 0;

    
        .info{ 
            h4{
                margin-bottom: 2rem;
                font-family: var(--ft);
                font-style: normal;
                font-weight: 400;
                font-size: 1.25rem;
                line-height: 150%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }

            .underline{
                margin-top: 1rem;
                height: 1px;
              
                position: relative;
                
                div{
                    width: 50px;
                    background-color: var(--primary);
                    height: 3px;
                    position: relative;
                    top: -1px;
                }
            }

            .location {

                & > div{
                    width: 95%;
                    border-radius:10px;
                    overflow: hidden;
                    height: 294px;
                }
            }

            .list{
                ul{
                    list-style: none;
                    padding: 0;
            
                    li{
                        font-family: var(--ft);
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1rem;
                        margin-bottom: 1rem;
                        color: rgba(160, 160, 160, 0.976471);
                        
                        a{
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            font-family: var(--ft);
                            font-style: normal;
                            font-weight: 300;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            text-align: right;
                            letter-spacing: 0.03em;
                            color: rgba(160, 160, 160, 0.976471);
                            transition: color .5s ease;
                            margin-left: .5rem;

                            span{
                                background-color: #fff;
                                color: var(--primary);
                                border-radius: 50%;
                                width: 26px;
                                height: 26px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 0.5rem;
                            }

                            &:hover{
                                color: #fff;
                            }
                        }
                    }
                }            
            }
        }

        .copy-right{
                border-top: 1px solid #333;
                margin-top: 3rem;
                padding-top: 2rem;
                font-family: var(--ft);
                font-style: normal;
                font-weight: 300;
                font-size: .9rem;
                line-height: 1.85rem;
                text-align: right;
                letter-spacing: 0.03em;
                color: rgb(160, 160, 160);
                word-spacing: -.01rem;

                a{
                    margin: 0.3rem;
                    color: var(--primary);
                    text-decoration: none;

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }

        @media screen and (max-width:991px) {
            .info{
                .list{
                    li {
                        & > span{
                            display: none;
                        }
                    }
                }
               

                .location{
                    & > div{
                        width: 90%;
                        overflow: hidden;
                        height: 300px;
                        margin-bottom: 3rem;
                    }
                }
            }
        }

        @media screen and (max-width:767px){
            .copy-right{
                div{
                    text-align: center;

                    .developed-by{
                        margin-top: .8rem;
                    }
                }
            }
        }

        @media screen and (max-width:575px) {
            .info{
                padding: 0 2rem;

                .location{
                    & > div{
                    width: 100%;
                    overflow: hidden;
                    height: 300px;
                    }
                }
            }
          
            
        }
    }

`;

