import Image from 'src/assets/images/hero.webp';

import { scroller } from 'react-scroll';
import HeroImg from 'src/assets/svg/hero.svg';
import { Helmet } from 'react-helmet-async';


function Hero() {
    return (
        <section className='hero'>
            <Helmet>
                <link rel="preload" as="image" href={Image} />
            </Helmet>
            <img width={'100%'} height={'100%'} src={Image} alt={"َQadis Cofe"} />
            <div className='hero-content'>
                <div className='content'>
                    <div className='container'>
                        <div className='img-con'>
                            <img src={HeroImg} alt="Qadis Cofe" height={'fit-content'} width={340} />
                        </div>
                        <p className=''>
                            يرحب بكم
                        </p>
                        <button className='' onClick={() => {
                            scroller.scrollTo('categoriesElement', {
                                duration: 600,
                                delay: 50,
                                smooth: true,
                                offset: 50, // Scrolls to element + 50 pixels down the page
                            })
                        }}>
                            قائمة الأصناف
                        </button>

                    </div>

                </div>
            </div>
            <div className='overlay'>
            </div>
        </section>
    )
}

export default Hero