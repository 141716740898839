import { styled } from 'styled-components';


export default styled.div`
    
    margin-bottom: .75rem;
    cursor:pointer;
    padding: 0 0 1.25rem;
    box-sizing: border-box;
    
    .img-con{
        position: relative;
        /* height: fit-content; */
        height: 232px;
        border-radius: 0.4rem;
        overflow: hidden;

        img{
            height: 100%;
            width: 100%;
            transition:all .5s ease ;
        }

        .content{
            font-family: var(--ft);
            height: 50%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition:height .5s ease ;
            z-index: 999;

            h3{
                transition:all 1s ease ;
                font-family: var(--ft);
                font-style: normal;
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 100%;
                letter-spacing: 0.03em;
                color: #FFFFFF;
            }

        }


        .overlay{
            background-color: #222;
            height:50%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0; 
            transition:height .5s ease ;
            background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.9) 61.46%, rgba(34, 34, 34, 0.9) 100%);

        }

        &:hover,
        &:active,
        &:focus{
            
            img{
                transform: scale(1.1);
            }
        
            .content{
                height: 70%;
            }

            .overlay{
                height: 70%;
            }
        }
    }


    @media (max-width: 767px){ 
        .img-con{
            height: fit-content;
            /* height: 195px; */
            .content{
                height: 45%;

                h3{
                    font-size: 1.25rem;
                    letter-spacing: .03rem;
                    font-weight: 400;
                }
            }
        }        
    }

    @media (max-width: 575px){    
        .img-con{
            /* height: 230px; */

            .content{
                height: 45%;

                h3{
                    font-size: 1.5rem;
                    letter-spacing: .03rem;
                }
            }
        }
        
    }

    @media (max-width: 575px) and (min-width: 400px){
        .img-con{
            /* height: 270px; */
        }
    }

`

