import Styled from './Styled.styled';
import { IconType } from 'react-icons';
import { VscError } from 'react-icons/vsc';

function Error(props: { msg: string, icon?: IconType, iconColor?: string }) {
    const Icon = props.icon ? props.icon : VscError;
    return (
        <Styled>
            <div className='icon' style={{ color: props.iconColor ? props.iconColor : '#c05858' }}>
                <Icon size={100} />
            </div>
            <div className='text'>
                {props.msg}
            </div>
        </Styled>)
}

export default Error