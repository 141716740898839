
import Styled from './Styled.styled';

import Hero from './Components/Hero/Hero';
import About from './Components/About/About';
import Categories from './Components/Categories/Categories';

function Home() {
    return (
        <Styled>
            <Hero />
            <Categories />
            <About />
        </Styled>
    )
}

export default Home