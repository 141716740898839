import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import Item from './Item/Item'
import { Spinner, Error as MyError } from 'src/components'
import BASE_URL from 'src/constants/vars';

export type CategoryType = {
    id: number,
    title: string,
    image: string
}

function Categories() {
    const featchCategory = async () => {
        const res = await fetch(`${BASE_URL}/products/categories/`);
        const jsonData = await res.json();
        return jsonData
    }

    const { data, error, isLoading, isError, isFetching } = useQuery({ queryKey: ['coursesData'], queryFn: () => featchCategory(), keepPreviousData: true })


    let Comp = null;

    if (isError) {
        let msg: string = "حدث خطأ ما"
        if (error instanceof Error) {
            msg = error.message;
        }
        Comp = <MyError msg={msg} />
    }
    else {
        Comp = isLoading || isFetching ?
            <>
                {Array.from(Array(9).keys()).map((item) => (
                    <div key={`skl-${item}`} className='col-12 col-md-6 col-lg-4' >
                        <Item />
                    </div>
                ))
                }
            </ >
            :
            data && data !== undefined ?
                data.length > 0 ?
                    data.map((item: CategoryType) => (
                        <div key={`cat-${item.id}`} className='col-md-6 col-sm-6 col-lg-4 col-12'>
                            <Item item={item} />
                        </div>

                    ))
                    :
                    <p className='not-found'>
                        لا يتم إضافة أي أصناف
                    </p>
                :
                null

    }


    return (
        <section className='categories'>
            <div className='container'>
                <div className='row pt-4'>
                    {Comp}
                </div>
            </div>
        </section >
    )
}

export default Categories