import Image from 'src/assets/images/coffee.jpg';
import Styled from './Styled.styled';
import { Title } from 'src/components';
import CatsList from './List/List';

function List() {
    return (
        <Styled>
            <header>
                <div className='title pt-4 col-12 products-title'>
                    <Title title={"الأصناف"} color={"#fff"} />
                </div>
                <img src={Image} alt={"Qadis Coffee"} />
            </header>
           
                <CatsList />
         
        </Styled>
    )
}

export default List