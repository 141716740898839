import { styled } from 'styled-components';


export default styled.section`

    .hero{
        height: calc(100vh - 60px);
        overflow: hidden;
        position: relative;
        
        .hero-content{
            height: 100%;
            width: 100%;
            position: absolute;
            background-color: #41414170;
            z-index: 9;
            display: flex;
            justify-content: center;
            /* align-items: center; */
            flex-direction: column;
            top: 0;
            left: 0;

            .content{
                margin-top: 2rem;

                .img-con{
                    width: 340px;
                    height: fit-content;

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            
                p{
                    padding-right: 1.5rem;
                    margin-top: 1rem;
                    font-family:var(--ft);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 2.5rem;
                    line-height: 125%;
                    /* identical to box height */
                    text-align: right;
                    color: #FFFFFF;
                }
                
                button{
                    margin-right: 1.5rem;
                    margin-top: 2.25rem;
                    background: #00C051;
                    border-radius: 5px;
                    font-family: var(--ft);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.025em;
                    color: #FFFFFF;
                    border: unset;
                    padding: .85rem 1.5rem;
                    transition: background .2s ease-in-out;

                    &:hover,
                    &:active{
                        background: #0F9F4C;
                    }
                }
            }
        }

        .overlay{
            position: absolute;
            width: 100%;
            height: 60%;
            left: 0px;
            top: 0px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        }

        & > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top center;
            animation: hero 8s ease-in-out .5s infinite alternate forwards;
        }

        @keyframes hero {
            0% {
                transform: scale(1);
            }

            100% {
                transform: scale(1.2);
            }
        }

        @media (max-width: 991px){
            height:470px;

            .hero-content {

                .content{
                    margin-top: 3rem;

                    .img-con{
                        width:240px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    p{
                        font-size: 1.75rem;
                        margin-top: .75rem;
                        margin-bottom: 0;
                    }

                    button{
                        padding: .75rem 1.25rem;
                        font-size: .9rem;
                        transition: unset;
                        margin-top: 2rem;
                    }
                }    
            }
        }

        @media (max-width: 767px){
            height: 395px;

            .hero-content {
                .content{
                    margin-top: 2.7rem;

                    .img-con{
                        width:180px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    p{
                        padding-right: .5rem;;
                        font-size: 1.25rem;
                        line-height: 100%;
                    }

                    button{
                        margin-right: .5rem;
                        font-size: .8rem;
                        padding: .7rem 1rem;
                    }
                }    
            }
        }

        @media (max-width: 575px){            
            height: 365px;

            .hero-content {
                padding-right: 1.2rem;

                .content{
                    .img-con{
                        width:168px;
                        height:85px;

                        img {
                            width: 100%;
                            height: 100%;
                            }
                        }
                    }
            }
        }
    }

    .categories{
        padding: 5rem 0;
     
        @media (max-width: 767px){
            padding: 3rem 0;
            
        }
    }

    .about-us{
        padding: 5rem 0 7rem;
    
        .img-con{
            position: relative;
            margin: auto;
            width: 300px;
            height: 300px;

            img{
                width: 100%;
                height: 100%;
            }
        }

        .content-con{
            display: flex;
            align-items: center;
        }

    .title{
        font-family: var(--ft);
        font-size: 2.5rem;
        line-height: 3rem;
        text-align: right;
        letter-spacing: 0.02em;
        color: #222222;
        font-weight: 600;
        word-spacing: -0.2rem;
        margin-bottom: 1.25rem;
    }

    .text{
        font-family: var(--ft);
        font-style: normal;
        font-weight: 300;
        font-size: 1.1rem;
        line-height: 250%;
        text-align: right;
        letter-spacing: 0.03em;
        color: #777777;
    }

        @media (max-width: 991px){
            padding: 4rem 0 5rem;
            
            .img-con{
                margin-bottom: 1.75rem;
                width: auto !important;
                height: 325px;
            
                img{
                    border-radius:1rem;
                }

                span{
                    display: none;
                }
            }

            .content-con{
                .content{
                    .text{
                        text-align: center;
                    }
                }
            }
        }

    @media (max-width: 767px){

            .img-con{
                margin-bottom: 1.3rem;
                height: 300px;
            }
    }

    @media (max-width: 575px){
        padding: 3rem 1.25rem 5rem;

        .img-con{
            width: auto ;
            height: 260px;
        
            img{
                border-radius:1rem;
            }

            span{
                display: none;
            }
        }

        .content-con{
            .content{
                .title{
                    font-size: 2.25rem;
                    margin-bottom: .75rem;
                }

                .text{
                    font-size: .9rem;
                }

            }
        }



    }

    }

`