import { styled } from 'styled-components';


export default styled.div`
    padding: 10rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text{
      
        font-family: var(--ft);
        font-size: 2rem;
        color: #414141;
        margin-top: 1rem;
    
    }

`

