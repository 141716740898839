import React from 'react';
import { FcQuestions } from 'react-icons/fc';
import Styled from './Styled.styled';

function Page404() {
    return (
        <Styled>

            <FcQuestions size={100} />

            <div className='text'>
                لم يتم العثور على الصفحة
            </div>

        </Styled>
    )
}

export default Page404