import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import SMLogo from 'src/assets/svg/sm_logo.svg';
import Logo from 'src/assets/svg/logo.svg';

import { NavLink } from 'react-router-dom';
import { IoSearchOutline } from 'react-icons/io5';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

import IconMenu from 'src/components/Icons/Menu';





type linkType = {
    id: string,
    to?: string,
    name: string,
    menu?: linkType[],
    href?: string
}

type linksType = linkType[]

const links: linksType = [
    {
        id: "link-1",
        to: "/",
        name: "الرئيسية",
    },
    {
        id: "link-3",
        to: "/categories",
        name: "الأصناف",
    },
    {
        id: "link-2",
        to: "https://maps.app.goo.gl/VM9uBFzG4nLCVGWK7?g_st=ic",
        name: "موقعنا على قوقل",
    }

    ,
    {
        id: "link-4",
        name: "صفحتنا على فيسبوك",
        to: "https://www.facebook.com/profile.php?id=61552186814200",

    }

]

export default () => {
    const expand = 'xxl';
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [expanded, setExpanded] = useState(false);

    return (<Navbar className='my-nav' expand={false} expanded={expanded}>
        <Container>
            <Navbar.Brand href="/">
                <img height={50} width={135} src={Logo} alt="Qadis Coffe" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={() => setExpanded(true)}>
                <IconMenu width={"1.8rem"} height="1.8rem" />
            </Navbar.Toggle>
            <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"

            >
                <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                        <img src={SMLogo} alt="Qadis Coffe" width={112} height={41} />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        {links.map(item => (
                            item.menu === undefined || item.menu.length < 1 ?
                                <Nav.Link key={item.id} as={"div"}  >
                                    <NavLink to={item.to || '/'}>
                                        {item.name}
                                    </NavLink>
                                </Nav.Link>
                                :
                                <NavDropdown
                                    title={item.name}
                                    key={item.id}
                                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                                >
                                    {item?.menu.map(i => (

                                        <NavDropdown.Item key={item.id} as={"div"}>
                                            <NavLink to={i.to || '/'}>
                                                {i.name}
                                            </NavLink>
                                        </NavDropdown.Item>
                                    ))}

                                </NavDropdown>
                        ))}


                    </Nav>
                    <Form className="d-flex mt-4 search-form" onSubmit={(e: any) => {
                        e.preventDefault();
                        setExpanded(false);
                        navigate('/products/search', {
                            state: {
                                search: search
                            }
                        })
                    }}>
                        <Form.Control
                            onChange={(e: any) => {
                                setSearch(e.target.value)
                            }}
                            type="search"
                            placeholder="بحث"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button type='submit' className='search-btn'>
                            <IoSearchOutline size={20} />
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
    </Navbar >);
}




