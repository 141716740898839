import { useNavigate } from "react-router-dom"
import Skeleton from 'react-loading-skeleton';
import Styled from './Styled.styled';

function Item({ item }: any) {
    const navigate = useNavigate();
    return (
        <Styled onClick={() => {
            navigate(`/categories/${item.slug}`, { state: { title: item.title } });
        }} >
            <div className='img-con'>
                {
                    item ?
                        <>
                            <img src={item.image} alt={item.title} loading="lazy" height={'fit-content'} width={'100%'} />
                            <div className='content'>
                                <h3>
                                    {item.title}
                                </h3>
                            </div>
                            <div className='overlay'>

                            </div>
                        </> : <Skeleton height={'100%'} />
                }

            </div>
        </Styled>

    )
}

export default Item