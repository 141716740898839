import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { Styled } from '../Styled.styled';
import ProductsPagination from '../Pagination';
import Item from '../Item';
import { Error as MyError, Title } from 'src/components';
import Image from 'src/assets/images/coffee.jpg';
import BASE_URL from 'src/constants/vars';
import { ProductType } from '../Types';



function List() {
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const params = useParams();
    const location = useLocation();

    const catTitle = location.state ? location.state.title : null;


    const featchCourses = async () => {
        const res = await fetch(`${BASE_URL}/products/categories/${params.slug}/?page=${page}`);
        const jsonData = await res.json();
        return jsonData
    }

    const { data, error, isLoading, isError, isFetching } = useQuery({ queryKey: ['productsData', catTitle, page], queryFn: () => featchCourses(), keepPreviousData: true })

    const pagesNum = data !== undefined ? data.pages_num : 0;


    useEffect(() => {
        if (!catTitle) {
            navigate('/categories', { replace: true })
        }
    }, [])

    let Comp = null;

    if (isError) {
        let msg: string = "حدث خطأ ما"
        if (error instanceof Error) {
            msg = error.message;
        }
        Comp = <MyError msg={msg} />
    }
    else {
        Comp = isLoading || isFetching ?
            <>
                {Array.from(Array(9).keys()).map((item) => (
                    <div key={`skl-${item}`} className='col-12 col-md-6 col-lg-4' >
                        <Item catName={catTitle} />
                    </div>
                ))
                }

            </ >
            :
            data !== undefined ?
                data?.results.length > 0 ? data.results.map((item: ProductType) => (
                    <div key={item.slug} className='col-12 col-md-6 col-lg-4'>
                        <Item item={item} catName={catTitle} />
                    </div>
                )) : <p className='not-found'>
                    لم يتم إضافة أي منتج
                </p>
                :
                []
    }

    return (
        <Styled>
            <header>
                <div className='title'>
                    <div className='title products-title pt-4'>
                        <Title title={catTitle} color={"#fff"} />
                    </div>
                </div>
                <img src={Image} alt={"Qadis Coffee"} />
            </header>
            <section className='container'>

                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            {Comp}
                        </div>
                    </div>
                    {
                        pagesNum > 0 ?
                            <div className='col-12'>
                                <ProductsPagination page={page}
                                    result={data.results.length}
                                    next={!isError ? data.next : null}
                                    previous={!isError ? data.previous : null}
                                    setPage={setPage}
                                    pagesNum={pagesNum} />
                            </div> :
                            null
                    }
                </div>
            </section>
        </Styled>
    )
}

export default List