import { lazy, Suspense } from 'react';
import Styled from './Styled.styled';

import { Outlet } from 'react-router-dom'


import Nav from './Nav/Nav';
// import Footer from './Footer/Footer';
import Spinner from 'src/components/Spinner/Spinner';

import { useTranslation } from 'react-i18next';


const Footer = lazy(() => import('./Footer/Footer'));

function Layout() {
    const { i18n } = useTranslation();

    return (
        <Styled className={['abs-con', i18n.language === 'ar' ? 'ar' : ''].join(' ')} >
            <header>
                <Nav />
            </header>
            <div className='content'>
                <Outlet />
            </div>
            <Suspense fallback={<Spinner height={100} width={100} />}>
                <Footer />
            </Suspense>
        </Styled>
    )
}

export default Layout