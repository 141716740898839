import { styled } from 'styled-components';


export default styled.article`
    
    header{
        height: 300px;
        position: relative;
        object-fit: cover;
        object-position: center top;
        
        .title{ 
            display: flex;
            align-items:center;
            justify-content: center;
            position: relative;
            z-index: 9;
            height: 100%;
            background-color: #110c039e;

            h4{
                color: #fff;
                font-family: var(--ft);
                font-size: 3rem;
                line-height: 3rem;
                text-align: right;
                letter-spacing: 0.02em;
            }
        }

        img{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center top;
        }
    }
    
    .categories{
        padding: 5rem 0;

        @media (max-width: 767px){
            padding: 3rem 0;
          
        }

    }

    @media screen and (max-width:767px){
        header{
            .title{
                h4{
                    font-size: 2.25rem;
                }
            }
        }

        section.container{
            .s-header{
                flex-wrap: wrap;
                justify-content: center;
                
                h4{
                    margin-bottom: 1.5rem;
                }
                form{
                    width: 100%;

                    input{
                        width: 90%;
                    }
                }
            }
        }
    }

`

