import Image from 'src/assets/svg/about.svg';
import { Title } from 'src/components';

function About() {
    return (
        <section className='about-us'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <Title title={"نبذة عنا"} />
                    </div>
                    <div className='col-12 col-lg-5 pe-lg-5'>
                        <div className='img-con'>
                            <img src={Image} alt="About Us" width={300} height={300}
                                loading='lazy' />
                        </div>
                    </div>
                    <div className='col-12 col-lg-7 content-con ps-lg-5'>
                        <div className="content">

                            <p className='text'>
                                في مقهانا، نحن نفخر بتوفير أجواء مريحة  لعملائنا, ينصب تركيزنا على تقديم  القهوة و الأطعمة والمشروبات عالية الجودة  فريقنا مكرس لتقديم خدمة عملاء استثنائية وضمان أن يشعر كل زائر بالرضا والسعادة. تفضل بزيارتنا واختبر التزامنا بالجودة والسرعة
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default About;