
import SpinnerImage from 'src/assets/svg/spinner.svg';


function Spinner({ width, height }: any) {
  return (
    <div className='spinner'>
      <img src={SpinnerImage} alt='Spinner'
        height={height} width={width} />
    </div>)
}

export default Spinner