import { useQuery } from '@tanstack/react-query';
import { Element } from 'react-scroll';
import Item from 'src/pages/categories/List/Item/Item';

import { Error as MyError, Title } from 'src/components'
import BASE_URL from 'src/constants/vars';


export type CategoryType = {
    id: number,
    title: string,
    image: string
}

function Categories({ hideTitle }: { hideTitle?: boolean }) {
    const featchCategory = async () => {
        const res = await fetch(`${BASE_URL}/products/categories/`);
        const jsonData = await res.json();
        return jsonData
    }

    const { data, error, isLoading, isError, isFetching } = useQuery({ queryKey: ['coursesData'], queryFn: () => featchCategory(), keepPreviousData: true })


    let Comp = null

    if (isError) {
        let msg: string = "حدث خطأ ما"
        if (error instanceof Error) {
            msg = error.message;
        }
        Comp = <MyError msg={msg} />
    }
    else {
        Comp = isLoading || isFetching ?
            <>
                {Array.from(Array(9).keys()).map((item) => (
                    <div key={`skl-${item}`} className='col-10 mx-auto col-md-6 col-lg-4' >
                        <Item />
                    </div>
                ))
                }
            </ >
            :
            data && data !== undefined ?
                data.length > 0 ?
                    data.map((item: CategoryType) => (
                        <div key={`cat-${item.id}`} className='col-sm-6 col-lg-4 col-9 mx-auto mx-xl-0 mx-lg-0 mx-md-0 mx-sm-0'>
                            <Item item={item} />
                        </div>

                    ))
                    :
                    <p className='not-found'>
                        لم يتم إضافة أي أصناف
                    </p>
                :
                null

    }


    return (

        <section className='categories'>
            <Element name='categoriesElement'>
                <div className='container'>
                    <div className='row pt-4'>
                        {hideTitle ? null : <div className=''>
                            <Title title={"الأصناف"} />
                        </div>}

                        {Comp}
                    </div>
                </div>
            </Element>
        </section >
    )
}

export default Categories