// icon:menu | Ant Design Icons https://ant.design/components/icon/ | Ant Design
import * as React from "react";

function IconMenu(props: any) {
    return (
        <svg
            viewBox="0 0 1024 1024"
            fill="#fff"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
        </svg>
    );
}

export default IconMenu;
